import React from 'react'
import moment from "moment";
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import { makeStyles, Theme } from "@material-ui/core/styles";

export default function ShipmentExceptions() {
    const DateFormatter = (text) => {
        // Regular expression to match YYYY-MM-DD formatted dates
        const dateRegex = /\d{4}-\d{2}-\d{2}/g;

        const formattedText = text.replace(dateRegex, (match) => {
            return moment(match).format('DD-MMM-YYYY');
        });

        return <p>{formattedText}</p>;
    };
    const shipmentExceptionData = {
        "shipmentExceptions": [
            // {
            //     "severity": 2,
            //     "createdAt": "2023-12-29",
            //     "exceptionType": "CN_EXTENDED_POD_STORAGE",
            //     "description": "Container MEDU2488108 was discharged from vessel on 2023-12-17 and gated out on 2023-12-29",
            //     "title": "Extended container storage time"
            // },
            // {
            //     "severity": 1,
            //     "createdAt": "2023-12-17",
            //     "exceptionType": "OS_ARRIVED_LATE",
            //     "description": "ETA was 2023-12-16 but ATA is 2023-12-17",
            //     "title": "Shipment arrived late"
            // },
            {
                "severity": 1,
                "createdAt": "2024-02-07",
                "exceptionType": "CN_EXTENDED_POO_STORAGE",
                "description": "Container MEDU2488108 gated in on 2024-02-03 and was loaded on vessel on 2024-02-07",
                "title": "Extended container storage time"
            }
        ]
    }
    return (
        <div> <Stepper
            activeStep={shipmentExceptionData.shipmentExceptions.length}
            orientation="vertical"
            sx={{
                display: "flex",
                border: "2px soild red",
                paddingLeft: "10px",
            }}
            class='my-stepper'
        >
            {shipmentExceptionData.shipmentExceptions.map((step, index) => (
                <Step key={index} >
                    <StepLabel>
                        <div
                            sx={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                                textTransform: "capitalize",
                            }}
                        >
                            {step.title}
                        </div>
                        <div
                            sx={{
                                fontSize: "14px",
                                color: "black",
                                textTransform: "capitalize",
                                mt: 1
                            }}
                        >
                            <span style={{ color: '#a7a5ad' }}>Exception Date:</span> {moment(step.createdAt).format('DD-MMM-YYYY')}

                        </div>

                        <div
                            sx={{
                                fontSize: "14px",
                                color: "black",
                                textTransform: "capitalize",
                                mt: 1,
                                display: 'flex'
                            }}
                        >
                            <span style={{ color: '#a7a5ad' }}>Description:</span> &nbsp;<span> {
                                DateFormatter(step.description)

                            }</span>

                        </div>
                    </StepLabel>

                </Step>
            ))}
        </Stepper></div>
    )
}
