import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { getEditBulkUploadResubmit, uploads } from '../pages/const';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import Swal from 'sweetalert2';


const ExcelUploader = ({ filePath, responseDataSend, tabKey }) => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="white" />,
    })
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const [showLoader, setShowLoader] = useState(false);
    // const [excelData, setExcelData] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [fileName, setFileName] = useState()
    const [file, setFile] = useState(null);
    const [excelFilePath, setExcelFilePath] = useState()
    const [fileType, setFileType] = useState('Create')
    const [selectValue, setSelectValue] = useState('');
    // const [uploadType,setUploadType] = useState('')
    console.log(responseDataSend, "resppsenddata")


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile ? selectedFile.name : '');
        setFileUploaded(true);
    };
    const changeHandler = (event) => {
        const selectedValue = event.target.value;
        sessionStorage.setItem("selectedType", selectedValue)
        setFileName('');
        // setUploadType(event.target.value)
        setFile(null);
        setFileUploaded(false);
        setSelectValue(selectedValue); // Set select value state
        switch (selectedValue) {
            case 'Materials':
                setExcelFilePath('/Material.xlsx');
                break;
            case 'Planning':
                setExcelFilePath('/PlanningUpload.xlsx');
                break;
            case 'Soft Demand':
                setExcelFilePath('/SoftDemand.xlsx');
                break;
            default:
                setExcelFilePath('');
        }
    }
    const preview = () => {
        setShowLoader(true)
        let uploadType = selectValue
        const formData = new FormData();
        formData.append('file', file);
        if (responseDataSend === undefined) {
            uploads(formData, selectValue).then(res => {
                console.log(res.data.status, "statud")
                if (res.data.status.code === 200) {
                    // responseDataSend(res.data);
                    setShowLoader(false)
                    Swal.fire({
                        title: "Success",
                        icon: "success"
                    })
                    setFile('')
                    setSelectValue('')
                }
                else {
                    Swal.fire({
                        title: "Oops...",
                        text: res.data.status.code,
                        icon: "error"
                    })
                }
            }).catch((err) => {
                console.log(err)
            });
        } else {
            
            getEditBulkUploadResubmit(formData, responseDataSend.id).then(res => {
                console.log(res.data.status, "statud")
                if (res.data.status.code === 200) {
                    // responseDataSend(res.data);
                    setShowLoader(false)
                    Swal.fire({
                        title: "Success",
                        icon: "success"
                    })
                    setFile('')
                    setSelectValue('')
                    setFileName(null)
                    sessionStorage.removeItem('selectedValue');
                }
                else {
                    Swal.fire({
                        title: "Oops...",
                        text: res.data.status.code,
                        icon: "error"
                    })
                }
            }).catch((err) => {
                console.log(err)
            });
        }
    }
    useEffect(() => {
        console.log(selectValue, "upload type")
        let uploadType = sessionStorage.getItem('selectedType')
        if (uploadType === 'Materials' || uploadType === 'Planning' || uploadType === 'Soft Demand') {
            setSelectValue(uploadType);
            setFileName(uploadType);
            switch (uploadType) {
                case 'Materials':
                    setExcelFilePath('/Material.xlsx');
                    break;
                case 'Planning':
                    setExcelFilePath('/PlanningUpload.xlsx');
                    break;
                case 'Soft Demand':
                    setExcelFilePath('/SoftDemand.xlsx');
                    break;
                default:
                    setExcelFilePath('');
            }
        }

        if (filePath) {
            setFile(filePath);
            setFileUploaded(true);
        }

        setValue('fileType', uploadType);
    }, [tabKey]);
    return (

        <div className="excel-uploader mt-4">
            <div className="row">
                <div className="col-3">
                    <label htmlFor="exampleSelect">Filter By:</label>
                    <select
                        onChange={changeHandler}
                        className="form-control form-control-md"
                        id="exampleSelect"
                        value={selectValue} // Bind the select value to state
                    >
                        <option>Uploads</option>
                        <option value="Materials">Materials</option>
                        <option value="Planning">Planning</option>
                        <option value="Soft Demand">Soft Demand</option>
                    </select>
                </div>
                <div className="col-3">
                    <div>
                        <a href={process.env.PUBLIC_URL + excelFilePath} download style={{ color: 'blue', cursor: 'pointer', marginLeft: '7px' }}>
                            <i className="fa fa-download" aria-hidden="true"></i> Download Sample Template
                        </a>
                    </div>
                    <div className="excel-uploader mb-5" style={{ marginTop: '8px' }}>
                        <input
                            type="file"
                            accept=".xlsx,.xls,.xlxs"
                            onChange={handleFileChange}
                            style={{ border: "1px solid black", borderRadius: "4px", padding: "5px" }}
                        />

                    </div>
                </div>
                <div className="col-4" style={{ display: 'flex', alignItems: 'center', marginTop: '-20px' }}>
                    <button
                        style={{ marginTop: "-4px", marginLeft: "6%" }}
                        className="btn btn-sm primary-btn loggedasButton"
                        disabled={!fileUploaded || showLoader}
                        onClick={preview}
                    >
                        {showLoader ? (
                            <section {...containerProps} style={{ marginTop: "0px", display: 'inline-block' }}>
                                {indicatorEl}
                            </section>
                        ) : (
                            "Submit"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ExcelUploader;

