import { icon } from '@fortawesome/fontawesome-svg-core';

import React, { useEffect, useState } from 'react'
import { GoogleMap, InfoWindow, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
// import { Alert, AlertTitle } from '@mui/material';
// import { useOutletContext } from 'react-router-dom';
export default function RouteMaps() {
    let icon =
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000";
    let iconbetween =
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|008000";
    let iconorigin =
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|f4c919";
        const [infoWindowPosition, setInfoWindowPosition] = useState(null);
        const [originInfoWindow, setOriginInfoWindow] = useState(false);
        const [destinationInfoWindow, setDestinationInfoWindow] = useState(false);
        const [centerCoordinates, setCenterCoordinates] = useState();
        const [isMapShow, setIsMapShow] = useState(false)
    const seaRouteCoordinates = [
        {
            "lat": 38.84352493286133,
            "lng": 26.95013427734375
        },
        {
            "lat": 38.846150102790006,
            "lng": 26.94953710306194
        },
        {
            "lat": 38.841091077624135,
            "lng": 26.923251902960263
        },
        {
            "lat": 38.82922291400661,
            "lng": 26.871079618944787
        },
        {
            "lat": 38.81745613721013,
            "lng": 26.826665041419457
        },
        {
            "lat": 38.80579074723471,
            "lng": 26.790008170384272
        },
        {
            "lat": 38.794125357259276,
            "lng": 26.753351299349088
        },
        {
            "lat": 38.782459967283856,
            "lng": 26.716694428313904
        },
        {
            "lat": 38.76321033883255,
            "lng": 26.64468876433643
        },
        {
            "lat": 38.73637647190538,
            "lng": 26.53733430741667
        },
        {
            "lat": 38.72106342527993,
            "lng": 26.47826713994444
        },
        {
            "lat": 38.7172711989562,
            "lng": 26.467487261919743
        },
        {
            "lat": 38.70766304951306,
            "lng": 26.425048478468263
        },
        {
            "lat": 38.6922389769505,
            "lng": 26.350950789589998
        },
        {
            "lat": 38.640799798408416,
            "lng": 26.317061018950042
        },
        {
            "lat": 38.55334551388681,
            "lng": 26.323379166548392
        },
        {
            "lat": 38.46947650844206,
            "lng": 26.29874591099113
        },
        {
            "lat": 38.38919278207416,
            "lng": 26.24316125227825
        },
        {
            "lat": 38.334059962753365,
            "lng": 26.204946799414166
        },
        {
            "lat": 38.30407805047967,
            "lng": 26.184102552398876
        },
        {
            "lat": 38.25267398765432,
            "lng": 26.15274885275676
        },
        {
            "lat": 38.17984777427733,
            "lng": 26.110885700487813
        },
        {
            "lat": 38.13620551287613,
            "lng": 26.010554916439983
        },
        {
            "lat": 38.12174720345073,
            "lng": 25.851756500613266
        },
        {
            "lat": 38.10801236726185,
            "lng": 25.71164970336462
        },
        {
            "lat": 38.09500100430947,
            "lng": 25.59023452469405
        },
        {
            "lat": 38.07176163467362,
            "lng": 25.30831147952187
        },
        {
            "lat": 38.0382942583543,
            "lng": 24.865880567848084
        },
        {
            "lat": 37.99083913089757,
            "lng": 24.630034565049982
        },
        {
            "lat": 37.92939625230343,
            "lng": 24.600773471127564
        },
        {
            "lat": 37.83563110975477,
            "lng": 24.55658219312477
        },
        {
            "lat": 37.70954370325159,
            "lng": 24.49746073104159
        },
        {
            "lat": 37.611136511554285,
            "lng": 24.442216151282846
        },
        {
            "lat": 37.54040953466284,
            "lng": 24.39084845384854
        },
        {
            "lat": 37.48754243617199,
            "lng": 24.348514170590587
        },
        {
            "lat": 37.45253521608173,
            "lng": 24.31521330150899
        },
        {
            "lat": 37.41752799599146,
            "lng": 24.28191243242739
        },
        {
            "lat": 37.3825207759012,
            "lng": 24.24861156334579
        },
        {
            "lat": 37.34797018023853,
            "lng": 24.217340980719218
        },
        {
            "lat": 37.31387620900348,
            "lng": 24.18810068454767
        },
        {
            "lat": 37.24550318618244,
            "lng": 24.12810015948602
        },
        {
            "lat": 37.142851111775414,
            "lng": 24.03733940553426
        },
        {
            "lat": 37.06276348511227,
            "lng": 23.967773576355963
        },
        {
            "lat": 37.00524030619301,
            "lng": 23.91940267195112
        },
        {
            "lat": 36.87579984096218,
            "lng": 23.808808234301758
        },
        {
            "lat": 36.674442089419784,
            "lng": 23.635990263407876
        },
        {
            "lat": 36.43890915332444,
            "lng": 23.461083876999005
        },
        {
            "lat": 36.16920103267614,
            "lng": 23.284089075075148
        },
        {
            "lat": 36.030510229263996,
            "lng": 23.110443755584914
        },
        {
            "lat": 36.02283674308799,
            "lng": 22.940147918528304
        },
        {
            "lat": 36.02508601503189,
            "lng": 22.75173948033291
        },
        {
            "lat": 36.03725804509566,
            "lng": 22.545218440998724
        },
        {
            "lat": 36.064083045095664,
            "lng": 22.022868440998725
        },
        {
            "lat": 36.10556101503189,
            "lng": 21.184689480332906
        },
        {
            "lat": 36.13145650701001,
            "lng": 20.623655509882475
        },
        {
            "lat": 36.14176952103003,
            "lng": 20.339766529647434
        },
        {
            "lat": 36.15141220977274,
            "lng": 20.055807733433824
        },
        {
            "lat": 36.16038457323813,
            "lng": 19.77177912124165
        },
        {
            "lat": 36.168685863511776,
            "lng": 19.48769033814122
        },
        {
            "lat": 36.176316080593665,
            "lng": 19.203541384132535
        },
        {
            "lat": 36.183274587701185,
            "lng": 18.919341944358678
        },
        {
            "lat": 36.189561384834334,
            "lng": 18.63509201881965
        },
        {
            "lat": 36.19517594681001,
            "lng": 18.350801326335613
        },
        {
            "lat": 36.2001182736282,
            "lng": 18.066469866906573
        },
        {
            "lat": 36.20438795209166,
            "lng": 17.78210738658308
        },
        {
            "lat": 36.20798498220039,
            "lng": 17.49771388536513
        },
        {
            "lat": 36.210909063047445,
            "lng": 17.21329913004547
        },
        {
            "lat": 36.21316019463285,
            "lng": 16.9288631206241
        },
        {
            "lat": 36.21473818856194,
            "lng": 16.64441563811613
        },
        {
            "lat": 36.215643044834714,
            "lng": 16.359956682521556
        },
        {
            "lat": 36.21587468770788,
            "lng": 16.075496042536656
        },
        {
            "lat": 36.21543311718143,
            "lng": 15.79103371816143
        },
        {
            "lat": 36.214318370219374,
            "lng": 15.506579499219876
        },
        {
            "lat": 36.21253044682169,
            "lng": 15.222133385711995
        },
        {
            "lat": 36.22722736384214,
            "lng": 15.023182746718541
        },
        {
            "lat": 36.258409121280714,
            "lng": 14.909727582239514
        },
        {
            "lat": 36.28388479626518,
            "lng": 14.807952844922623
        },
        {
            "lat": 36.30365438879553,
            "lng": 14.71785853476787
        },
        {
            "lat": 36.32342398132588,
            "lng": 14.627764224613117
        },
        {
            "lat": 36.34319357385624,
            "lng": 14.537669914458366
        },
        {
            "lat": 36.41052455833759,
            "lng": 14.307797846327638
        },
        {
            "lat": 36.52541693476994,
            "lng": 13.938148020220936
        },
        {
            "lat": 36.61292616932663,
            "lng": 13.635684422572872
        },
        {
            "lat": 36.67305226200765,
            "lng": 13.40040705338345
        },
        {
            "lat": 36.71263648126112,
            "lng": 13.247151276591552
        },
        {
            "lat": 36.73167882708704,
            "lng": 13.175917092197185
        },
        {
            "lat": 36.747982957405284,
            "lng": 13.118379961917677
        },
        {
            "lat": 36.761548872215855,
            "lng": 13.074539885753033
        },
        {
            "lat": 36.77468649266378,
            "lng": 13.035951362739612
        },
        {
            "lat": 36.787395818749076,
            "lng": 13.002614392877412
        },
        {
            "lat": 36.80031230403581,
            "lng": 12.967264969570156
        },
        {
            "lat": 36.813435948523995,
            "lng": 12.929903092817844
        },
        {
            "lat": 36.828428229877,
            "lng": 12.884564948012503
        },
        {
            "lat": 36.84528914809482,
            "lng": 12.831250535154135
        },
        {
            "lat": 36.86306862534369,
            "lng": 12.768424227390987
        },
        {
            "lat": 36.88176666162363,
            "lng": 12.696086024723057
        },
        {
            "lat": 36.90675546283395,
            "lng": 12.609187156562836
        },
        {
            "lat": 36.93803502897466,
            "lng": 12.507727622910325
        },
        {
            "lat": 36.96614924521535,
            "lng": 12.422113915062788
        },
        {
            "lat": 36.99109811155602,
            "lng": 12.352346033020229
        },
        {
            "lat": 37.01323320004423,
            "lng": 12.284133503623206
        },
        {
            "lat": 37.03255451068,
            "lng": 12.217476326871726
        },
        {
            "lat": 37.054131542328946,
            "lng": 12.148316808718265
        },
        {
            "lat": 37.07796429499107,
            "lng": 12.07665494916283
        },
        {
            "lat": 37.111520919833566,
            "lng": 11.970197699239979
        },
        {
            "lat": 37.154801416856415,
            "lng": 11.828945058949715
        },
        {
            "lat": 37.230292914072365,
            "lng": 11.657978379170899
        },
        {
            "lat": 37.391846660185934,
            "lng": 11.356957300269846
        },
        {
            "lat": 37.47033402968325,
            "lng": 11.161749260436897
        },
        {
            "lat": 37.50474982372508,
            "lng": 10.98937869785335
        },
        {
            "lat": 37.532331772217454,
            "lng": 10.851229226350608
        },
        {
            "lat": 37.56455180650764,
            "lng": 10.689843991734156
        },
        {
            "lat": 37.597753184084276,
            "lng": 10.52594385446314
        },
        {
            "lat": 37.631935904947355,
            "lng": 10.359528814537562
        },
        {
            "lat": 37.64898056852603,
            "lng": 10.262729582896942
        },
        {
            "lat": 37.648840477967425,
            "lng": 10.221954447863448
        },
        {
            "lat": 37.65005046499281,
            "lng": 10.001753882001276
        },
        {
            "lat": 37.77551697921762,
            "lng": 9.361413970354352
        },
        {
            "lat": 37.82744859993778,
            "lng": 9.110372902683872
        },
        {
            "lat": 37.84766749856155,
            "lng": 9.035184041774194
        },
        {
            "lat": 37.863359591101535,
            "lng": 8.978222851581545
        },
        {
            "lat": 37.87452487755773,
            "lng": 8.939489332105923
        },
        {
            "lat": 37.88627523363624,
            "lng": 8.889951272276084
        },
        {
            "lat": 37.89861065933708,
            "lng": 8.829608672092029
        },
        {
            "lat": 37.91887235452022,
            "lng": 8.730000615359685
        },
        {
            "lat": 37.947060319185674,
            "lng": 8.591127102079056
        },
        {
            "lat": 37.97508478786863,
            "lng": 8.452147517714337
        },
        {
            "lat": 38.00294576056909,
            "lng": 8.313061862265528
        },
        {
            "lat": 38.03064266014208,
            "lng": 8.173871005508374
        },
        {
            "lat": 38.0581754865876,
            "lng": 8.034574947442877
        },
        {
            "lat": 38.085543667220726,
            "lng": 7.895174573806514
        },
        {
            "lat": 38.112747202041476,
            "lng": 7.7556698845992855
        },
        {
            "lat": 38.13978552291974,
            "lng": 7.616061781489236
        },
        {
            "lat": 38.16665862985553,
            "lng": 7.476350264476366
        },
        {
            "lat": 38.19336595936829,
            "lng": 7.336536251123135
        },
        {
            "lat": 38.21990751145803,
            "lng": 7.196619741429544
        },
        {
            "lat": 38.24628272738849,
            "lng": 7.056601668811595
        },
        {
            "lat": 38.27249160715965,
            "lng": 6.91648203326929
        },
        {
            "lat": 38.29853359687421,
            "lng": 6.776261784026303
        },
        {
            "lat": 38.324408696532146,
            "lng": 6.635940921082636
        },
        {
            "lat": 38.350116357169654,
            "lng": 6.495520409418845
        },
        {
            "lat": 38.37565657878672,
            "lng": 6.35500024903493
        },
        {
            "lat": 38.40102881744749,
            "lng": 6.214381420612609
        },
        {
            "lat": 38.426233073151955,
            "lng": 6.07366392415188
        },
        {
            "lat": 38.44464307809968,
            "lng": 5.9693751626713905
        },
        {
            "lat": 38.45625883229066,
            "lng": 5.90151513617114
        },
        {
            "lat": 38.46783541722996,
            "lng": 5.833633319741693
        },
        {
            "lat": 38.479372832917576,
            "lng": 5.7657297133830525
        },
        {
            "lat": 38.49087102185352,
            "lng": 5.697804432884681
        },
        {
            "lat": 38.50232998403778,
            "lng": 5.629857478246578
        },
        {
            "lat": 38.513749662263216,
            "lng": 5.56188896606875
        },
        {
            "lat": 38.525130056529825,
            "lng": 5.493898896351197
        },
        {
            "lat": 38.53647110992568,
            "lng": 5.425887386502183
        },
        {
            "lat": 38.547772822450774,
            "lng": 5.357854436521706
        },
        {
            "lat": 38.55903513749076,
            "lng": 5.289800164624069
        },
        {
            "lat": 38.57025805504563,
            "lng": 5.221724570809272
        },
        {
            "lat": 38.581441518800965,
            "lng": 5.153627774095355
        },
        {
            "lat": 38.592585528756764,
            "lng": 5.085509774482318
        },
        {
            "lat": 38.603690028900864,
            "lng": 5.017370691789466
        },
        {
            "lat": 38.614755019233264,
            "lng": 4.949210526016799
        },
        {
            "lat": 38.62578044404644,
            "lng": 4.881029397782527
        },
        {
            "lat": 38.63676630334039,
            "lng": 4.81282730708665
        },
        {
            "lat": 38.64771254171454,
            "lng": 4.744604375343842
        },
        {
            "lat": 38.65861915916888,
            "lng": 4.6763606025541025
        },
        {
            "lat": 38.67049344826397,
            "lng": 4.603783236831833
        },
        {
            "lat": 38.68333540899978,
            "lng": 4.526872278177033
        },
        {
            "lat": 38.69388214319756,
            "lng": 4.457287274257055
        },
        {
            "lat": 38.70213365085729,
            "lng": 4.395028225071898
        },
        {
            "lat": 38.70749482839518,
            "lng": 4.356036246791522
        },
        {
            "lat": 38.70996567581122,
            "lng": 4.3403113394159245
        },
        {
            "lat": 38.71243441685916,
            "lng": 4.324585345891364
        },
        {
            "lat": 38.71490105153901,
            "lng": 4.30885826621784
        },
        {
            "lat": 38.71736557918828,
            "lng": 4.293130101908105
        },
        {
            "lat": 38.71982799980699,
            "lng": 4.27740085296216
        },
        {
            "lat": 38.72228831273352,
            "lng": 4.26167052089491
        },
        {
            "lat": 38.72474651796789,
            "lng": 4.245939105706356
        },
        {
            "lat": 38.72720261484938,
            "lng": 4.230206608913591
        },
        {
            "lat": 38.729656603378004,
            "lng": 4.214473030516615
        },
        {
            "lat": 38.73210848289395,
            "lng": 4.198738372034768
        },
        {
            "lat": 38.73455825339721,
            "lng": 4.18300263346805
        },
        {
            "lat": 38.73700591422887,
            "lng": 4.167265816337931
        },
        {
            "lat": 38.739451465388925,
            "lng": 4.151527920644412
        },
        {
            "lat": 38.74189490621934,
            "lng": 4.135788947911237
        },
        {
            "lat": 38.74433623672014,
            "lng": 4.120048898138407
        },
        {
            "lat": 38.74677545623416,
            "lng": 4.104307772851826
        },
        {
            "lat": 38.74921256476141,
            "lng": 4.088565572051493
        },
        {
            "lat": 38.751647561645655,
            "lng": 4.072822297265515
        },
        {
            "lat": 38.7540804468869,
            "lng": 4.057077948493889
        },
        {
            "lat": 38.78965307736034,
            "lng": 3.890005635189603
        },
        {
            "lat": 38.85836545306599,
            "lng": 3.571605357352656
        },
        {
            "lat": 38.923970581905394,
            "lng": 3.219338347684189
        },
        {
            "lat": 38.986468463878566,
            "lng": 2.833204606184203
        },
        {
            "lat": 39.04983938652196,
            "lng": 2.483214050674488
        },
        {
            "lat": 39.114083349835596,
            "lng": 2.169366681155044
        },
        {
            "lat": 39.1557756141801,
            "lng": 1.9412302060849456
        },
        {
            "lat": 39.17491617955548,
            "lng": 1.798804625464193
        },
        {
            "lat": 39.2447567068412,
            "lng": 1.7398124266830504
        },
        {
            "lat": 39.365297196037254,
            "lng": 1.7642536097415178
        },
        {
            "lat": 39.4254708848311,
            "lng": 1.7618056384658747
        },
        {
            "lat": 39.42527777322272,
            "lng": 1.732468512856121
        },
        {
            "lat": 39.425077292723635,
            "lng": 1.7031315560349753
        },
        {
            "lat": 39.42486944333384,
            "lng": 1.6737947680024376
        },
        {
            "lat": 39.42465422525023,
            "lng": 1.6444581611615945
        },
        {
            "lat": 39.424431638472804,
            "lng": 1.6151217355124459
        },
        {
            "lat": 39.4242016832124,
            "lng": 1.5857855034566057
        },
        {
            "lat": 39.423964359469025,
            "lng": 1.556449464994074
        },
        {
            "lat": 39.423719667467466,
            "lng": 1.5271136325250723
        },
        {
            "lat": 39.42346760720773,
            "lng": 1.4977780060496002
        },
        {
            "lat": 39.42320817892856,
            "lng": 1.4684425979662592
        },
        {
            "lat": 39.42294138262997,
            "lng": 1.4391074082750492
        },
        {
            "lat": 39.42266721856463,
            "lng": 1.40977244937298
        },
        {
            "lat": 39.422385686732554,
            "lng": 1.3804377212600514
        },
        {
            "lat": 39.42209678740037,
            "lng": 1.3511032363314825
        },
        {
            "lat": 39.421800520568084,
            "lng": 1.3217689945872735
        },
        {
            "lat": 39.42149688651628,
            "lng": 1.2924350084207958
        },
        {
            "lat": 39.42118588524497,
            "lng": 1.2631012778320496
        },
        {
            "lat": 39.42086751704865,
            "lng": 1.2337678152125193
        },
        {
            "lat": 39.42054178192733,
            "lng": 1.2044346205622052
        },
        {
            "lat": 39.42067826890032,
            "lng": 1.181986127475648
        },
        {
            "lat": 39.42127697796761,
            "lng": 1.1664223359528478
        },
        {
            "lat": 39.42187361304394,
            "lng": 1.1508582779700873
        },
        {
            "lat": 39.42246817412932,
            "lng": 1.1352939535273663
        },
        {
            "lat": 39.42306066106522,
            "lng": 1.1197293644570436
        },
        {
            "lat": 39.42365107385164,
            "lng": 1.1041645107591194
        },
        {
            "lat": 39.42423941233115,
            "lng": 1.0885993942664527
        },
        {
            "lat": 39.42482567650375,
            "lng": 1.0730340149790436
        },
        {
            "lat": 39.4254098662131,
            "lng": 1.0574683747304334
        },
        {
            "lat": 39.42599198145922,
            "lng": 1.0419024735206222
        },
        {
            "lat": 39.42657202208684,
            "lng": 1.0263363131836343
        },
        {
            "lat": 39.42714998809597,
            "lng": 1.0107698937194698
        },
        {
            "lat": 39.427725879332456,
            "lng": 0.9952032169626648
        },
        {
            "lat": 39.428299695796305,
            "lng": 0.9796362829132192
        },
        {
            "lat": 39.42887143733445,
            "lng": 0.9640690934062945
        },
        {
            "lat": 39.429441103946914,
            "lng": 0.9485016484418907
        },
        {
            "lat": 39.4300086954817,
            "lng": 0.9329339498556806
        },
        {
            "lat": 39.43057421193882,
            "lng": 0.9173659976476642
        },
        {
            "lat": 39.43113765316741,
            "lng": 0.9017977936540608
        },
        {
            "lat": 39.43169901916748,
            "lng": 0.88622933787487
        },
        {
            "lat": 39.431701349014276,
            "lng": 0.8553356268668506
        },
        {
            "lat": 39.431144642707814,
            "lng": 0.8091166606300025
        },
        {
            "lat": 39.430569646339734,
            "lng": 0.762898457212799
        },
        {
            "lat": 39.42997635991003,
            "lng": 0.7166810166152402
        },
        {
            "lat": 39.4293647848137,
            "lng": 0.6704643873027294
        },
        {
            "lat": 39.428734921050726,
            "lng": 0.6242485692752666
        },
        {
            "lat": 39.42613428966746,
            "lng": 0.37961856440858893
        },
        {
            "lat": 39.41927719116211,
            "lng": -0.28494772315025
        }
    ]
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q",
    });

    const mapOptions = {
        zoom: 3,
        styles: [
            {
                "featureType": "all",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        color: '#e2e2e2',
                    }
                ]
            }
        ]
    };
    const dottedLineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 2,
    };
    const handleMarkerClick = (position) => {
        console.log(position, "position")
        setInfoWindowPosition(position);
    };
      useEffect(() => {
        setIsMapShow(true)
      }, []);

    //   function initMap(latitude, longitude) {
    //     // Google Maps API will be available here
    //     let icon =
    //     "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000";
    //   let iconbetween=
    //     "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|008000";
    //     const map = new window.google.maps.Map(document.getElementById("map"), {
    //       zoom: 4,
    //       center: { lat: latitude, lng: longitude },
    //       styles: [
    //         {
    //           "featureType": "all",
    //           "elementType": "all",
    //           "stylers": [
    //             {
    //               "saturation": -30
    //             }
    //           ]
    //         }
    //       ]
    //     });


    //     // Define sea route coordinates


    //     // Create sea route polyline
    //     const seaRoutePath = new window.google.maps.Polyline({
    //       path: seaRouteCoordinates,
    //       geodesic: true,
    //       strokeColor: '#007BFF',
    //       strokeOpacity: 1,
    //       strokeWeight: 2,
    //     });
    //     seaRoutePath.setMap(map);

    //     // Create markers
    //     const originIcon = {
    //       url: icon,
    //       scaledSize: new window.google.maps.Size(25, 30), // Adjust the size as needed
    //     };

    //     const destinationIcon = {
    //       url: iconbetween,
    //       scaledSize: new window.google.maps.Size(25, 30), // Adjust the size as needed
    //     };

    //     // Create markers
    //     const originMarker = new window.google.maps.Marker({
    //       position: seaRouteCoordinates[0],
    //       map: map,
    //       icon: originIcon,
    //       title: 'Origin'
    //     });

    //     const destinationMarker = new window.google.maps.Marker({
    //       position: seaRouteCoordinates[seaRouteCoordinates.length - 1],
    //       map: map,
    //       icon: destinationIcon,
    //       title: 'Destination'
    //     });

    //     // Info windows
    //     const originInfo = new window.google.maps.InfoWindow({
    //       content: 'Origin - Valencia'
    //     });

    //     const destinationInfo = new window.google.maps.InfoWindow({
    //       content: 'Destination - Izmir'
    //     });

    //     originMarker.addListener('mouseover', function () {
    //       originInfo.open(map, originMarker);
    //     });

    //     originMarker.addListener('mouseout', function () {
    //       originInfo.close();
    //     });

    //     destinationMarker.addListener('mouseover', function () {
    //       destinationInfo.open(map, destinationMarker);
    //     });

    //     destinationMarker.addListener('mouseout', function () {
    //       destinationInfo.close();
    //     });

    //     // Fit map bounds
    //     let bounds = new window.google.maps.LatLngBounds();
    //     bounds.extend(originMarker.position);
    //     bounds.extend(destinationMarker.position);
    //     map.fitBounds(bounds);
    //   }

    return (
        <div>
            {/* {isMapShow ? */}
            <div>
                {isLoaded ?
                    isMapShow ?
                        <GoogleMap
                            mapContainerStyle={{ height: "80vh", width: "60vw" }}
                            center={seaRouteCoordinates[0]}
                            zoom={mapOptions.zoom}
                            options={{
                                styles: mapOptions.styles,
                                mapId: 'your-map-id',
                            }}
                        >
                            <Polyline
                                path={seaRouteCoordinates}
                                options={{
                                    strokeColor: '#007BFF',
                                    strokeWeight: 2,
                                }}
                            />
                            {/* <Polyline
            path={seaRouteCoordinates.slice(35, seaRouteCoordinates.length)}
            options={{
              strokeColor: '#ffb217',
              strokeOpacity: 0,
              strokeWeight: 5,
              icons: [
                {
                  icon: dottedLineSymbol,
                  offset: '0',
                  repeat: '10px', // Adjust the repeat value to change the spacing of the dots
                },
              ],
            }}
          /> */}
                            {/* -------------- origin marker -------------- */}
                            <Marker
                                position={seaRouteCoordinates[0]}
                                icon={iconorigin}
                                onClick={() => handleMarkerClick(seaRouteCoordinates[0])}
                            />
                            {/* <Marker
            position={seaRouteCoordinates[35]}
            icon={iconbetween}
          // icon={{
          //   path: "M10 0a10 10 0 1 0 20 0a10 10 0 1 0-20 0",
          //   scale: 0.8,
          //   fillColor: "#173a64",
          //   fillOpacity: 1,
          //   strokeColor: "#173a64",
          //   strokeWeight: 2
          // }}
          // onClick={() => handleMarkerClick(seaRouteCoordinates[5])}
          /> */}
                            <Marker
                                position={seaRouteCoordinates[seaRouteCoordinates.length - 1]}
                                icon={icon}
                                onClick={() => handleMarkerClick(seaRouteCoordinates[seaRouteCoordinates.length - 1])}
                            />
                            {/* {originInfoWindow && (
                                <InfoWindow
                                    position={seaRouteCoordinates[0]}
                                    onCloseClick={() => setOriginInfoWindow(false)}
                                    options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                                >
                                    <div>Origin - {props.origin}</div>
                                </InfoWindow>
                            )} */}
                            {/* {destinationInfoWindow && (
                                <InfoWindow
                                    position={seaRouteCoordinates[seaRouteCoordinates.length - 1]}
                                    onCloseClick={() => setDestinationInfoWindow(false)}
                                    options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                                >
                                    <div>Destination - {props.destination}</div>
                                </InfoWindow>
                            )} */}
                        </GoogleMap> : "" : ''
                }
            </div>
            {/* {!isMapShow &&
                <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    The shipment route haven't been identified yet.
                </Alert>} */}

        </div >
    );
}
