import React, { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import { getPreviewLineStatus, getPreviewStatus, softDemandUpload } from "../pages/const";
import Swal from 'sweetalert2';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import axios from 'axios';
import moment from 'moment';
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';

const UploadStatusLinesPreview = () => {
    const columns = [
        {
            name: "PO #",
            selector: row => row.orderNumber,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Validation Status",
            selector: row => row.validationStatus,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Error",
            selector: row => row.error,
            sortable: true,
            minWidth: '400px'
        },
        {
            name: "Error Status",
            selector: row => row.errorExist,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Inovice #",
            selector: row => row.invoiceNumber,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "HBL #",
            selector: row => row.hbl,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "MBL #",
            selector: row => row.mbl,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "AWB #",
            selector: row => row.awb,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Container #",
            selector: row => row.containerNumber,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Carrier Id",
            selector: row => row.carrierId,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Carrier Name",
            selector: row => row.carrierName,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Consignee Id",
            selector: row => row.consigneeId,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Consignee Name",
            selector: row => row.consigneeName,
            sortable: true,
            minWidth: '200px'
        },
        
        {
            name: "Commodity Type",
            selector: row => row.commodityType,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "HS Code",
            selector: row => row.hsCode,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "HS Code Description",
            selector: row => row.hsCodeDescription,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "HZ Goods",
            selector: row => row.hzGoods,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Height",
            selector: row => row.height,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Length",
            selector: row => row.length,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Inco Terms",
            selector: row => row.incoTerms,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Invoice Date",
            selector: row => row.invoiceDate,
            sortable: true,
            minWidth: '200px'
        },
        
        {
            name: "Material Id",
            selector: row => row.materialId,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Material Name",
            selector: row => row.materialName,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Material Category",
            selector: row => row.materialCategory,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Material Description",
            selector: row => row.materialDescription,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Shipment",
            selector: row => row.modeOfShipment,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "No.Of Packages",
            selector: row => row.noOfPackages,
            sortable: true,
            minWidth: '200px'
        },
        
        {
            name: "Package Type",
            selector: row => row.packageType,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Package Weight",
            selector: row => row.packageWeight,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Pickup Date",
            selector: row => row.pickupDate,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Pickup Location",
            selector: row => row.pickupLocation,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Quantity",
            selector: row => row.quantity,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Width",
            selector: row => row.width,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Requested Date Of Delivery",
            selector: row => row.requestedDateOfDelivery,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Special Instructions",
            selector: row => row.specialInstructions,
            sortable: true,
            minWidth: '200px'
        },
        


    ];
    const [data, setData] = useState([]);
    const [errorCount, setErrorCount] = useState();
    const [totalCount, setTotalCount] = useState();
    const history = useHistory();
    const { id } = useParams();
    const customRowStyles = (row) => {
        // console.log("row.errorExist:", row.children[2][0].props.row.errorExist); // Log errorExist value
        return {
            color: row.children[2][0].props.row.errorExist === false ? 'inherit !important' : (row.children[2][0].props.row.errorExist === true ? 'red !important' : ''),
        }
    };
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="white" />,
    })
    const [showLoader, setShowLoader] = useState(false);
    const uploadLineInfo = (data) => {
        console.log(data,"line data")
        
    }
    const handleBackClick = () => {
        history.push('/uploads', { propActiveState: 'Upload Status' });
    };
    useEffect(() => {
        console.log(id,"idddd")
        getPreviewData()
    }, [])
    const getPreviewData = () => {
        getPreviewLineStatus(id).then((res) => {
            console.log(res.data, "respo")
            if (res.data.data.length !== 0) {
                setData(res.data.data);
                // const countWithErrorExist = data.filter(item => {
                //     // console.log("Processing item:", item.errorExist);
                //     return item.errorExist === true;
                // }).length;
                // const totalCount = data.length;
                // setErrorCount(countWithErrorExist);
                // setTotalCount(totalCount)
                // console.log("Count of objects with errorExist=false:", errorCount);
            }
        })
    }
    return (
        <>
            <div className='row'>
            <div class="col-sm-12" >
                    <span style={{ display: "flex", justifyContent: "end" }}>
                        <div className="btnBack" onClick={handleBackClick}>
                            <IoMdArrowRoundBack size={20}></IoMdArrowRoundBack>&nbsp; Back
                        </div>
                    </span>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-md-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        //selectableRows
                        // selectableRowsComponent={BootyCheckbox}
                        // onSelectedRowsChange = {handleChange}
                        dense
                        selectableRowsHighlight='true'
                        compact
                        highlightOnHover='true'
                        striped
                        customStyles={{
                            rows: {
                                style: customRowStyles
                            }
                        }}
                    />
                </div>
            </div>


        </>
    )
}

export default UploadStatusLinesPreview;