import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { softDemandCalendar, getPurchaseOrdersLinkedToWorkId, getOrderWiseMaterial, getMaterialWiseLeadDays, getsoftDemandRisk, poAction } from '../pages/const';
import Swal from 'sweetalert2';
import { data } from 'jquery';

const localizer = momentLocalizer(moment);

const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
        backgroundColor: isSelected ? '#3174ad' : '#f1c1c1', // Highlight color for selected event, default color otherwise
        borderRadius: '0px',
        opacity: isSelected ? 1 : 0.8, // Adjust opacity for selected event
        color: isSelected ? 'white' : 'black', // Set text color for better contrast
        border: 'none',
        height: '100%'
    };
    return {
        style,
    };
};
const events = [
    {
        title: 'WORKID002',
        start: new Date(2024, 1, 1), // February 14, 2024, 10:00 AM
        end: new Date(2024, 1, 3), // February 14, 2024, 12:00 PM
    },
    {
        title: 'WORKID004',
        start: new Date(2024, 1, 14), // February 14, 2024, 10:00 AM
        end: new Date(2024, 1, 18), // February 14, 2024, 12:00 PM
    },
    {
        title: 'WORKID005',
        start: new Date(2024, 1, 22), // February 15, 2024, 1:00 PM
        end: new Date(2024, 1, 24), // February 15, 2024, 4:00 PM
    },
    {
        title: 'DD00123',
        start: "2024-04-03T00:00:00.000Z", // February 15, 2024, 1:00 PM
        end: "2024-04-03T00:00:00.000Z", // February 15, 2024, 4:00 PM
    },
];

const CalendarComponent = () => {
    const [offcanvas, setoffcanvas] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [popoverContent, setPopoverContent] = useState('');
    const [workEvents, setWorkEvents] = useState([])
    const [selectedWorkEvent, setSelectedWorkEvent] = useState({})
    const [poList, setPoList] = useState([])
    const [clickedIndex, setClickedIndex] = useState(0);
    const [rowData, setRowData] = useState({})
    const [risks, setRisks] = useState([])
    const [riskData, setRiskData] = useState()
    const history = useHistory();
    const [recomendedSuppliers, setRecomendedSuppliers] = useState([])
    const [showRiskMitigations, setShowRiskMitigations] = useState(false);
    const [dataWiseMonthsDropdown, setDataWiseMonthsDropdown] = useState([]);
    const [showRecomendationsIcon, setshowRecomendationsIcon] = useState(false);

    const [siteId, setSiteId] = useState(sessionStorage.getItem("siteId"));
    const [siteName, setSiteName] = useState(sessionStorage.getItem("siteName"));

    const columns = [
        {
            name: 'Material Name',
            selector: row => row.materialName,
        },
        {
            name: 'QTY',
            selector: row => row.quantity,
        },
        {
            name: 'UOM',
            selector: row => row.uom,
        },
        {
            name: 'NEED BY DATE',
            selector: row => moment(row.needByDate).format('DD-MMM-YYYY'),
        },
        {
            name: 'LEAD TIME',
            selector: row => row.leadDays,
            cell: row => (
                <>
                    {row.leadDays}
                    <i
                        className="fa fa-info-circle"
                        style={{ fontSize: 14, marginLeft: 10, color: "blue", cursor: "pointer" }}
                        onClick={() => riskMitigation(row)} // Make sure to use an arrow function here
                    ></i>
                </>
            ),
        },
    ];
    const [currentDate, setCurrentDate] = useState(new Date());

    const handleMonthChange = (event) => {
        const { value } = event.target;
        const selectedDateObject = JSON.parse(value);
        const { month, year } = selectedDateObject;
        const newDate = new Date(year, month - 1, 1); //
        setCurrentDate(newDate);
    };

    // const handleInfoClick = () => {
    //     // Set popover content based on the qrcode or any other logic
    //     setPopoverContent(`Popover content for ID---000001`);
    //     // Toggle popover visibility
    //     setPopoverVisible(!popoverVisible);
    // };
    const riskMitigation = (data) => {
        setShowRiskMitigations(true)
        let riskPayload = {
            materialId: data.materialId,
            supplierId: rowData.supplierId,
            leadDays: data.leadDays,
            needByDate: data.needByDate
        }
        getMaterialWiseLeadDays(riskPayload).then(res => {
            if (res.status === 200) {
                setRisks(res.data.data)
                // setPoList(res.data.data)
                // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });

        let riskDataPayload = {
            orderRecommendedDate: rowData.orderRecommendedDate,
            leadDays: data.leadDays,
            needByDate: data.needByDate
        }
        getsoftDemandRisk(riskDataPayload).then(res => {
            if (res.status === 200) {
                setRiskData(res.data.data)
                // setPoList(res.data.data)
                // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });

    }
    const handleEventClick = (event) => {
        setoffcanvas(true)
        setSelectedWorkEvent(event)
        getPurchaseOrdersLinkedToWorkId({ siteId: siteId, workId: event.title }).then(res => {
            if (res.status === 200) {
                setPoList(res.data.data);
                if (res.data.data[0].orderStatus === 'Pending') {
                    setshowRecomendationsIcon(true)
                }
                else {
                    setshowRecomendationsIcon(false)
                }
                getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber);
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
    };

    const closeSideOverlay = () => {
        setoffcanvas(false);
        setPopoverVisible(false);
        // setPoList([]);
    };

    const handleBackClick = () => {
        history.push('/dashboard', { propActiveState: 'softDemand' });
    };
    const handleLiClickPo = (index, orderNumber, orderStatus) => {
        setClickedIndex(index);
        getOrderWiseMaterialData(orderNumber);
        if (orderStatus === 'Pending') {
            setshowRecomendationsIcon(true)
        }
        else {
            setshowRecomendationsIcon(false)
        }
    }
    const getOrderWiseMaterialData = (orderId) => {
        getOrderWiseMaterial({ orderId: orderId }).then(res => {
            if (res.status === 200) {
                setRowData(res.data.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    useEffect(() => {
        // setShowLoader(true)
        softDemandCalendar({ siteId: siteId }).then(res => {
            if (res.status === 200) {
                setWorkEvents(res.data.data)
                // setShowLoader(false)
                setDataWiseMonthsDropdown(res.data.months)
                setCurrentDate(new Date(res.data.months[0].year, res.data.months[0].month - 1, 1));

            }
        }).catch((err) => {
            console.log(err)
        });

    }, []);

    const handleRecomIconClick = () => {
        let riskPayload = {
            materialId: rowData["materialData"][0].materialId,
            supplierId: rowData.supplierId,
            leadDays: rowData["materialData"][0].leadDays,
            orderRecommendedDate: rowData.orderRecommendedDate,
            needByDate: rowData["materialData"][0].needByDate
        }
        getMaterialWiseLeadDays(riskPayload).then(res => {
            if (res.status === 200) {
                setRecomendedSuppliers(res.data.data)
            }
        })
    }

    const finalizePO = (ponumber) => {
        poAction({ poNumber: ponumber, action: 'Confirmed' }).then(res => {
            if (res.status === 200) {
                if (res.data.status.code === 'SUCCESS') {
                    Swal.fire({
                        title: "Success",
                        text: res.data.data,
                        icon: "success"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setoffcanvas(false);
                            softDemandCalendar({ siteId: siteId }).then(res => {
                                if (res.status === 200) {
                                    setWorkEvents(res.data.data)
                                    // setShowLoader(false)
                                }
                            }).catch((err) => {
                                console.log(err)
                            });

                        }
                    })
                }
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    // }, [])
    const handleChange = (rows) => {
        // rows.selectedRows will contain the indices or keys of the selected rows
        console.log('Selected rows:', rows.selectedRows);
        // You can perform any additional logic here, such as updating state
    };
    return (
        <div id="claenderView">
            <div class="row mb-2">
                <div class="col-sm-5">
                    {/* <h2 class="m-0 text-dark">Work Shedules at risk, &nbsp; &nbsp;
                        <span style={{ fontSize: 13 }}>
                            <strong>Site: </strong> <span className='textHighlighter'>{siteName}</span>
                        </span>
                    </h2> */}
                </div>
                <div class="col-sm-3" >
                    <div class="input-group">
                        {
                            dataWiseMonthsDropdown.length == 0 ?
                                <div><span className='redcolor' style={{ fontSize: 16, marginTop: 14 }}>No Risks Reported</span></div>
                                :
                                <select class="form-control form-control-sm" name="farmer" onChange={handleMonthChange} >
                                    {dataWiseMonthsDropdown.length !== 0 &&
                                        dataWiseMonthsDropdown.map((item, index) => (
                                            <option key={index} value={JSON.stringify(item)}>
                                                {item.monthsMap}
                                            </option>
                                        ))
                                    }
                                </select>
                        }
                    </div>
                </div>
                <div className='col-1'></div>
                <div class="col-sm-3" >
                    {/* <span style={{ display: "flex", justifyContent: "end" }}>
                        <div className="btnBack" onClick={handleBackClick}>
                            <IoMdArrowRoundBack size={20}></IoMdArrowRoundBack>&nbsp; Back
                        </div>
                    </span> */}
                </div>
            </div>
            <div className=''>
                {/* <div className='card-body'> */}
                <Calendar
                    localizer={localizer}
                    events={workEvents}
                    style={{ margin: '', height: '70vh', fontSize: "14px" }}
                    eventPropGetter={eventStyleGetter}
                    views={['month']}
                    onSelectEvent={handleEventClick}
                    date={currentDate}
                    onNavigate={(date) => setCurrentDate(date)}
                    selectable
                    onSelectSlot={(slotInfo) => console.log('Selected slot:', slotInfo)}
                    eventOverlapAccessor={() => true} // Allows events to overlap
                    eventOffsetAccessor={10} // Sets the vertical offset between overlapping events
                />
                {/* </div> */}
            </div>
            <div id='sideOverlay' className={`side-overlay ${offcanvas ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "85%" }}>
                <div className="overlay-content">
                    <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                    {/* <button className="close-button" >Close</button> */}
                    <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                        <div className='col-md-12'>
                            <div className='card-body'>
                                <div className='title row' style={{ justifyContent: "left" }}>
                                    <h2 className='p-1 ml-1 m-2 text-dark'>
                                        Purchase requisitions Linked to work
                                    </h2>
                                </div>
                                {poList.length !== 0 &&
                                    <>
                                        <div className='row mt-4 sideOverlaySummary'>
                                            <div className='col-4'>
                                                <strong>Work ID: </strong> {selectedWorkEvent.title}
                                            </div>
                                            <div className='col-3'>
                                                <strong>Start Date:</strong> {moment(selectedWorkEvent.start).subtract(1, 'day').format('DD-MMM-YYYY')}
                                            </div>
                                            <div className='col-3'>
                                                <strong>End Date:</strong> {moment(selectedWorkEvent.end).subtract(1, 'day').format('DD-MMM-YYYY')}
                                            </div>
                                            <div className='col-2' id="sideOverlayMenu">
                                                <div class="nav-item dropdown">
                                                    <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle">Take Action</a>
                                                    <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu shadow">
                                                        <li>
                                                            <NavLink exact to="#" className="nav-link">
                                                                Chat with supplier
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink exact to="#" className="nav-link">
                                                                Edit Order
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink exact to="#" className="nav-link">
                                                                Change Order
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink exact to="#" className="nav-link" onClick={() => finalizePO(rowData.orderId)}>
                                                                Finalize Order
                                                            </NavLink>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-4' style={{ border: '1px solid black' }}>
                                            <div className='col-md-3' style={{ borderRight: '1px solid black' }}>
                                                <div className='row mt-4'>
                                                    <ul>
                                                        {poList.map((item, index) => (
                                                            <li
                                                                key={index}
                                                                className={`${clickedIndex === index ? 'PoNumberListHighlighted' : 'PoNumberList'} ${item.risk === 0 ? 'noRisk' : 'risk'}`}
                                                                onClick={() => handleLiClickPo(index, item.orderNumber, item.orderStatus)}
                                                            >
                                                                {item.orderNumber}
                                                            </li>
                                                        ))}
                                                        {/* <li className="PoNumberList" style={{ color: "red" }}>POHD001</li>
                                                <li className="PoNumberList">POHD002</li>
                                                <li className="PoNumberList">POHD003</li>
                                                <li className="PoNumberList">POHD004</li>
                                                <li className="PoNumberList">POHD005</li>
                                                <li className="PoNumberList">POHD006</li> */}

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='col-md-9'>
                                                <div className='row mt-4' style={{ fontSize: 14 }}>
                                                    <div className='col-4' style={{ display: "flex" }}>
                                                        <strong>Supplier Code : </strong>&nbsp; {rowData.supplierId}
                                                        {showRecomendationsIcon &&
                                                            <div class="nav-item dropdown">
                                                                <a id="dropdownSubMenu2" href="#" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                    className="dropdown-toggle"
                                                                    onClick={handleRecomIconClick}>
                                                                    <i className="fa fa-info-circle"
                                                                        style={{ fontSize: 14, marginLeft: 10, color: "green", cursor: "pointer" }} >
                                                                    </i>
                                                                </a>
                                                                <ul aria-labelledby="dropdownSubMenu2" class="dropdown-menu shadow" style={{ whiteSpace: "nowrap" }}>
                                                                    {recomendedSuppliers.length !== 0 && recomendedSuppliers.map((supplier) => (
                                                                        <li key={supplier.preferredSupplierId}>
                                                                            <NavLink to="#" className="nav-link" onClick={() => rowData.supplierId = supplier.preferredSupplierId}>
                                                                                {supplier.preferredSupplierId} - {supplier.leadDays} days
                                                                            </NavLink>
                                                                        </li>
                                                                    ))
                                                                    }
                                                                    {/* <li>
                                                            <NavLink exact to="" className="nav-link">
                                                                SUP001 - 140 days
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink exact to="" className="nav-link">
                                                                SUP003 -  120 days
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink exact to="" className="nav-link">
                                                                SUP004 -  100 days
                                                            </NavLink>
                                                        </li> */}

                                                                </ul>
                                                            </div>
                                                        }
                                                        {/* <i
                                                    className="fa fa-info-circle"
                                                    style={{ fontSize: 14, marginLeft: 10, color: "green", cursor: "pointer" }}
                                                    onClick={() => handleInfoClick()}
                                                ></i> */}
                                                    </div>
                                                    <div className='col-4'>
                                                        <strong>Confirm Order By:</strong> <span style={showRecomendationsIcon === true ? { color: "red" } : { color: "green" }} > {moment(rowData.orderRecommendedDate).format('DD-MMM-YYYY')}</span>
                                                    </div>
                                                    <div className='col-4'>
                                                        <strong>Supply Site:</strong> {rowData.customerSiteId}
                                                    </div>
                                                </div>

                                                <div className='row mt-4'>
                                                    <div className='col-md-12'>
                                                        <DataTable
                                                            columns={columns}
                                                            data={rowData.materialData}
                                                            pagination
                                                            dense
                                                            // selectableRows // Enable row selection
                                                            selectableRowsHighlight // Highlight selected rows
                                                            highlightOnHover
                                                            striped
                                                            onSelectedRowsChange={handleChange} // Callback for handling selected row change
                                                        />
                                                    </div>
                                                </div>
                                                {showRiskMitigations &&
                                                    < div className='row mt-4' style={{ fontSize: "15px" }}>
                                                        <div className='col-7'>
                                                            <div className='title row' style={{ justifyContent: "center" }}>
                                                                <h2 class="m-2 text-dark">
                                                                    Risk
                                                                </h2>
                                                            </div>

                                                            {/* <div className="order-details">
                                                        <div className="detail-row">
                                                            <div className="detail-key">Order Confirmation Date</div>
                                                            <div className="detail-value">26/11/2023</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Lead Time</div>
                                                            <div className="detail-value">210 days</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Available Time - Installation</div>
                                                            <div className="detail-value">170 days</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Estimated Shipment Delay</div>
                                                            <div className="detail-value">40 days</div>
                                                        </div>
                                                    </div> */}

                                                            <tabel>
                                                                <tbody className='table table-striped' id='sideOverlaytable'>
                                                                    <tr>
                                                                        <td className='header'>Confirm Order Date </td>
                                                                        <td>{riskData && moment(riskData.orderConfirmationDate).format('DD-MMM-YYYY')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='header'>Lead Time <small>(in Days)</small></td>
                                                                        <td>{riskData && riskData.leadDays}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='header'>Available Time <small>for Installation in days</small></td>
                                                                        <td>{riskData && riskData.availableTime}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='header'>Estimated Shipment Delay <small>(in Days)</small></td>
                                                                        <td>{riskData && riskData.shipmentDelay}</td>
                                                                    </tr>

                                                                </tbody>
                                                            </tabel>
                                                        </div>
                                                        <div className='col-5' style={{ borderLeft: "1px solid #666" }}>
                                                            <div className='title row' style={{ justifyContent: "center" }}>
                                                                <h2 class="m-2 text-dark">
                                                                    RISK MITIGATION
                                                                </h2>
                                                            </div>
                                                            <p style={{ marginTop: 10 }}>
                                                                Recommended suppliers with lower Lead Time to get on-time delivery
                                                            </p>
                                                            {/* <ol className='ml-0' style={{ lineHeight: 2, fontSize: 13 }} >
                                                                {risks.map((item) => (
                                                                    <li><strong>{item.preferredSupplierId}</strong> </li>
                                                                ))}
                                                            </ol> */}
                                                            <table class="table table-condensed">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><strong>Supplier</strong></td>
                                                                        <td><strong>OnHand QTY</strong></td>
                                                                        <td><strong>Lead Days</strong></td>
                                                                    </tr>
                                                                    {risks.map((item) => (
                                                                        <tr>
                                                                            <td>{item.supplierName}</td>
                                                                            <td>{item.inventory} {item.uom}</td>
                                                                            <td>{item.leadDays}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                                {poList.length === 0 &&
                                    <>
                                        <div className='card mt-5'>
                                            <div className='card-body'>
                                                <div className="row" style={{ justifyContent: "center" }}>
                                                    <h2>No Data</h2>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


export default CalendarComponent;