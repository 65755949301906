import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap-accordion'
export default function ShipmentInfo() {
    const [cargoEsInfo,setCargoEsInfo] = useState([
        {
            title: 'Shipment Info',
            isOpen: true,
            shipmentInfo: [
                { header: '# Of Packages', field: 'NA' },
                { header: 'Package Type', field: 'NA' },
                { header: 'Shipment Weight', field: 'NA' },
                { header: 'Weight UOM', field: 'NA' },
                { header: 'Pickup Country', field: 'NA' },
                { header: 'Pickup Zipcode', field: 'NA' },
                { header: 'Drop Off Country', field: 'NA' },
                { header: 'Drop Off Zipcode', field: 'NA' },
                { header: 'Drop Off location', field: 'NA' },
                { header: 'Co2 Emissions', field: 'NA' },

            ]
        },
        {
            title: 'Port Info',
            isOpen: false, 
            originInfo: [
                { header: 'Port Of Origin', field: 'NA' },
                { header: 'Port Of Origin Code', field: 'NA' },
                { header: 'Departure Terminal', field: 'NA' },
                { header: 'Port Of Origin ATD', field: 'NA' },
                { header: 'Port Of Discharge', field: 'NA' },
                { header: 'Port Of Discharge Code', field: 'NA' },
                { header: 'Port Of Discharge ETA', field: 'NA' },
                { header: 'Port Of Discharge ATA', field: 'NA' },
            ]
        },

        {
            title: 'Container Info',
            isOpen: false, 
            containerInfo: [
                { header: 'Container #', field: 'NA' },
                { header: 'Container Seal #', field: 'NA' },
                { header: 'Container Type', field: 'NA' },
                { header: 'Container Size', field: 'NA' },
                { header: 'Container Tare Weight', field: 'NA' },
                { header: 'Container Gross Weight', field: 'NA' },
                { header: 'Container Net Weight', field: 'NA' },
                { header: 'Empty Container Pickup Depot', field: 'NA' },
                { header: 'Empty Container Pickup Date', field: 'NA' },
                { header: 'Empty Container Pickup Time', field: 'NA' },
            ]
        },
        {
            title: 'Package Info',
            isOpen: false, 
            PackageInfo: [
            ]
        },

    ])
    const toggleAccordion = (index) => {
        setCargoEsInfo(prevAccordions => {
          const newAccordions = [...prevAccordions];
          newAccordions[index].isOpen = !newAccordions[index].isOpen;
          return newAccordions;
        });
    };
    return (
        <div>
            {cargoEsInfo.map((item, index) => (
                <Accordion key={index}
                    title={item.title === 'Shipment Info' ? item.title : item.title}
                    isOpen={item.isOpen}
                    class="my-accordian"
                    onClick={() => toggleAccordion(index)}>
                    {item.title === 'Shipment Info' ?
                        <div class="row">
                            <div class="col-md-6 ">
                                {item.shipmentInfo.map((item1, index1) => (
                                    index1 < 5 &&
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between',padding:'5px' }}>
                                        <div style={{ color: "#333", fontSize: "12px", fontWeight: 'bold'}}  class="summaryHeader">{item1.header}</div>
                                        <div style={{ fontSize: "12px" }}>{item1.field}</div>
                                    </div>
                                ))}
                            </div>
                            <div class="col-md-6">
                                {item.shipmentInfo.map((item1, index1) => (
                                    index1 > 4 &&
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between',padding:'5px' }}>
                                        <div style={{ color: "#333", fontSize: "12px", fontWeight: 'bold' }} class="summaryHeader">{item1.header}</div>
                                        <div style={{ fontSize: "12px" }}>{item1.field}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        : item.title === 'Port Info' ? 
                        <div class="row">
                            <div class="col-md-6">
                                {item.originInfo.map((item1, index1) => (
                                    index1 < 4 &&
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between',padding:'5px' }}>
                                        <div style={{ color: "#333", fontSize: "12px", fontWeight: 'bold' }}  class="summaryHeader">{item1.header}</div>
                                        <div style={{ fontSize: "12px" }}>{item1.field}</div>
                                    </div>
                                ))}
                            </div>
                            <div class="col-md-6">
                                {item.originInfo.map((item1, index1) => (
                                    index1 > 3 &&
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between',padding:'5px' }}>
                                        <div style={{ color: "#333", fontSize: "12px", fontWeight: 'bold'}}  class="summaryHeader">{item1.header}</div>
                                        <div style={{ fontSize: "12px" }}>{item1.field}</div>
                                    </div>
                                ))}
                            </div>
                        </div> : item.title === 'Container Info' ? 
                        <div class="row">
                            <div class="col-md-6">
                                {item.containerInfo.map((item1, index1) => (
                                    index1 < 5 &&
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between',padding:'5px' }}>
                                        <div style={{ color: "#333", fontSize: "12px",  fontWeight: 'bold'}}  class="summaryHeader">{item1.header}</div>
                                        <div style={{ fontSize: "12px" }}>{item1.field}</div>
                                    </div>
                                ))}
                            </div>
                            <div class="col-md-6">
                                {item.containerInfo.map((item1, index1) => (
                                    index1 > 4 &&
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between',padding:'5px' }}>
                                        <div style={{ color: "#333", fontSize: "12px", fontWeight: 'bold'}}  class="summaryHeader">{item1.header}</div>
                                        <div style={{ fontSize: "12px" }}>{item1.field}</div>
                                    </div>
                                ))}
                            </div>
                        </div>:<div class="row">
                            
                        </div>
                    }
                </Accordion>
            ))}
        </div>
    )
}
