import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { productionRisksCalendar, getMaterialWiseLeadDays, supplierRecomendationForMaterial, getProductionSchedularRisk, getOrderWiseMaterial, poAction, getProductionScheduleLinkedToWorkId } from '../pages/const';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Swal from 'sweetalert2';
import ProductionRiskWorkSchedules from "./WorkSchedules"

export default function ProductionRiskLedger() {
    const [siteId, setSiteId] = useState(sessionStorage.getItem("siteId"));
    const [siteName, setSiteName] = useState(sessionStorage.getItem("siteName"));
    const [workEvents, setWorkEvents] = useState([])
    const [offcanvas, setoffcanvas] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [popoverContent, setPopoverContent] = useState('');

    const [selectedWorkEvent, setSelectedWorkEvent] = useState({})
    const [poList, setPoList] = useState([])
    const [clickedIndex, setClickedIndex] = useState(0);
    const [rowData, setRowData] = useState({})
    const [risks, setRisks] = useState([])
    const [riskData, setRiskData] = useState()
    const [selectedState, setSelectedState] = useState(1)
    const history = useHistory();
    const [recomendedSuppliers, setRecomendedSuppliers] = useState([])
    const [showRiskMitigations, setShowRiskMitigations] = useState(false);
    const [dataWiseMonthsDropdown, setDataWiseMonthsDropdown] = useState([]);
    const [showRecomendationsIcon, setshowRecomendationsIcon] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [riskDataList, setRiskDataList] = useState()
    const [onTrackDataList, setOnTrackDataList] = useState();
    const [materialNameSelectedToViewRisks, setMaterialNameSelectedToViewRisks] = useState('');
    const [workStartDateInfo, setWorkStartDateInfo] = useState();
    const [workEndDateInfo, setWorkEndDateInfo] = useState();
    let riskList = []
    let onTrackList = []
    const columns = [
        {
            name: 'Material Name',
            selector: row => (
                <span title={row.materialName}>
                    <span>{row.materialName}</span>
                </span>
            ),
            minWidth: "200px",

        },
        {
            name: 'QTY',
            selector: row => row.quantity,
        },
        {
            name: 'UOM',
            selector: row => row.uom,
        },
        {
            name: 'NEED BY DATE',
            selector: row => moment(row.needByDate).format('DD-MMM-YYYY'),
        },
        {
            name: 'PO NUMBER',
            selector: row => row.orderNumber,
            minWidth: "180px",
            selector: row => (
                <span title={row.orderNumber}>
                    <span>{row.orderNumber}</span>
                </span>
            ),
        },
        {
            name: 'SUPPLIER',
            selector: row => row.supplierId,
        },
        {
            name: 'DEVIATION DAYS',
            selector: row => row.deviationDays,
            cell: row => (
                <>
                    {row.deviationDays > 0 && <span style={{ color: "red" }}>{row.deviationDays}</span>}
                    <i
                        className="fa fa-info-circle"
                        style={{ fontSize: 14, marginLeft: 10, color: "grey", cursor: "pointer" }}
                        onClick={() => riskMitigation(row)}
                    ></i>
                </>
            ),
        }

    ];
    const [filteredWorkOrders, setFilteredWorkOrders] = useState([]);
    const changeHandler = (event) => {
        setSelectedState(event.target.value)
        setFilteredWorkOrders([])
        if (event.target.value === "1") {
            setFilteredWorkOrders(riskDataList)
        } else {
            setFilteredWorkOrders(onTrackDataList)
            setRisks([])
        }
        // filterData(workEvents);

    }
    const filterData = (dataValues) => {
        let data = []
        dataValues.filter((item) => {
            if (item.risk === selectedState) {
                data.push(item)
            }
        }
        )
        // alert(JSON.stringify(data))
        setFilteredWorkOrders(data);
    }

    const riskMitigation = (data) => {
        setMaterialNameSelectedToViewRisks(data.materialName);
        supplierRecomendationForMaterial(data).then(res => {
            if (res.status === 200) {
                setRisks(res.data.data)
                // setPoList(res.data.data)
                // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
        let riskPayLoad = {
            deviationDays: data.deviationDays,
            needByDate: data.needByDate
        }
        getProductionSchedularRisk(riskPayLoad).then(res => {
            if (res.status === 200) {
                setRiskData(res.data.data)
                // setPoList(res.data.data)
                // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });

    }


    useEffect(() => {
        // setShowLoader(true)

        productionRisksCalendar({ siteId: siteId, type: 'Both' }).then(res => {
            if (res.status === 200) {
                setWorkEvents(res.data.data);
                res.data.data.filter((item) => {
                    if (item.risk === 1) {
                        riskList.push(item)

                    } else {
                        onTrackList.push(item)
                    }
                })
                setRiskDataList(riskList)
                setFilteredWorkOrders(riskList)
                setOnTrackDataList(onTrackList)
                // filterData(res.data.data);
            }
        }).catch((err) => {
            console.log(err)
        });

    }, []);
    const taskcolumns = [
        {
            name: "Work ID",
            selector: "title",
            sortable: true,
            minWidth: '70px'
        },
        {
            name: "Description",
            selector: "workDescription",
            sortable: true,
            minWidth: '250px'
        },
        {
            name: "Start Date",
            selector: row => moment(row.start).format('DD-MMM-YYYY'),
            sortable: true,
            minWidth: '250px'
        },
        {
            name: "End Date",
            selector: row => moment(row.end).format('DD-MMM-YYYY'),
            sortable: true,
            minWidth: '250px'
        },
        {
            name: "Purchase Orders",
            sortable: true,
            minWidth: '120px',
            cell: (row) =>
                <i className="fas fa-eye view-btn" onClick={() => taskClickHandler(row)}></i>

        },


    ];
    const taskClickHandler = (event) => {
        setoffcanvas(true)
        setSelectedWorkEvent(event)
        setWorkStartDateInfo(event.start);
        setWorkEndDateInfo(event.end);
        getProductionScheduleLinkedToWorkId(event.title).then(res => {
            if (res.status === 200) {
                setPoList(res.data.data)
                // getOrderWiseMaterialData(res.data.data[clickedIndex].orderNumber)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
    }



    const closeSideOverlay = () => {
        setoffcanvas(false);
        setPopoverVisible(false);
        risks.length = 0;
    };

    const handleBackClick = () => {
        history.push('/dashboard', { propActiveState: 'productionRisks' });
    };
    const handleLiClickPo = (index, orderNumber, orderStatus) => {
        setClickedIndex(index);
        getOrderWiseMaterialData(orderNumber);
        if (orderStatus === 'Pending') {
            setshowRecomendationsIcon(true)
        }
        else {
            setshowRecomendationsIcon(false)
        }
    }
    const getOrderWiseMaterialData = (orderId) => {
        getOrderWiseMaterial({ orderId: orderId }).then(res => {
            if (res.status === 200) {
                setRowData(res.data.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    useEffect(() => {
        // setShowLoader(true)
        // softDemandCalendar({ siteId: siteId }).then(res => {
        //     console.log("showLoader", res)
        //     if (res.status === 200) {
        //         console.log("data111", res.data)
        //         setWorkEvents(res.data.data)
        //         // setShowLoader(false)
        //         setDataWiseMonthsDropdown(res.data.months)
        //         setCurrentDate(new Date(res.data.months[0].year, res.data.months[0].month - 1, 1));

        //     }
        // }).catch((err) => {
        //     console.log(err)
        // });

    }, []);

    const handleRecomIconClick = () => {
        let riskPayload = {
            materialId: rowData["materialData"][0].materialId,
            supplierId: rowData.supplierId,
            leadDays: rowData["materialData"][0].leadDays,
            orderRecommendedDate: rowData.orderRecommendedDate,
            needByDate: rowData["materialData"][0].needByDate
        }
        getMaterialWiseLeadDays(riskPayload).then(res => {
            if (res.status === 200) {
                setRecomendedSuppliers(res.data.data)
            }
        })
    }
    const renderCell = (cell) => {
        return (
            <span title={cell}>
                <span>{cell}</span>
            </span>
        );
    };


    // }, [])
    const handleChange = (rows) => {
        // rows.selectedRows will contain the indices or keys of the selected rows
        // You can perform any additional logic here, such as updating state
    };
    return (
        <div className="planning">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">WORK SHEDULES AT RISK, &nbsp; &nbsp;
                        <span style={{ fontSize: 13 }}>
                            <strong>Site: </strong> <span className='textHighlighter'>{siteName}</span>
                        </span> </h2>
                </div>
                <div class="col-6">
                    <span style={{ display: "flex", justifyContent: "end" }}>
                        <div className="btnBack" onClick={handleBackClick}>
                            <IoMdArrowRoundBack size={20}></IoMdArrowRoundBack>&nbsp; Back
                        </div>
                    </span>
                </div>
            </div>

            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }}>
                        <Tab label="Work Scheduler" eventKey="Work Scheduler">
                            <div className="row">
                                <div class="col-auto">
                                    <div class="form-inline">
                                        <label for="exampleSelect" class="mr-2">Filter By:</label>
                                        <select onChange={changeHandler} class="form-control form-control-sm" id="exampleSelect">

                                            <option value={1}>At Risk</option>
                                            <option value={0}>On Track</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <DataTable
                                        columns={taskcolumns}
                                        data={filteredWorkOrders}
                                        defaultSortField="taskID"
                                        pagination
                                        dense
                                        selectableRowsHighlight='true'
                                        compact
                                        highlightOnHover='true'
                                        striped
                                    />
                                </div>
                            </div>
                        </Tab>
                        <Tab label="Calendar View" eventKey="Calendar View">
                            <ProductionRiskWorkSchedules />
                        </Tab>

                    </Tabs>

                </div>
            </div>
            <div id='sideOverlay' className={`side-overlay ${offcanvas ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "75%" }}>
                <div className="overlay-content">
                    <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                    {/* <button className="close-button" >Close</button> */}
                    <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                        <div className='col-md-12'>
                            <div className='card-body'>
                                <div className='title row' style={{ justifyContent: "left" }}>
                                    <h2 className='p-1 ml-1 m-2 text-dark'>
                                        Purchase Orders Linked to work
                                    </h2>
                                </div>
                                <div className='row mt-4 sideOverlaySummary'>
                                    <div className='col-4'>
                                        <strong>Work ID: </strong>
                                        {poList.length > 0 && poList[0].workId}
                                    </div>
                                    <div className='col-3'>
                                        <strong>Work Start Date:</strong> {poList.length > 0 && moment(workStartDateInfo).format('DD-MMM-YYYY')}
                                    </div>
                                    <div className='col-3'>
                                        <strong>Work End Date:</strong> {poList.length > 0 && moment(workEndDateInfo).format('DD-MMM-YYYY')}
                                    </div>
                                </div>
                                <div className='row mt-4' style={{ border: '1px solid black' }}>

                                    <div className='col-md-12'>

                                        <div className='row mt-3'>
                                            <div className='col-md-12'>
                                                <DataTable
                                                    columns={columns}
                                                    data={poList}
                                                    pagination
                                                    //selectableRows
                                                    // selectableRowsComponent={BootyCheckbox}
                                                    // onSelectedRowsChange = {handleChange}
                                                    dense
                                                    selectableRowsHighlight='true'
                                                    compact
                                                    highlightOnHover='true'
                                                    striped
                                                />
                                            </div>
                                        </div>
                                        {risks.length > 0 &&
                                            <>
                                                <div className='row mt-4 pl-3'>
                                                    <h2>
                                                        <strong>Material Name : </strong> {materialNameSelectedToViewRisks}
                                                    </h2>
                                                </div>
                                                <div className='row mt-4' style={{ fontSize: "15px" }}>
                                                    <div className='col-6'>
                                                        <div className='title row' style={{ justifyContent: "center" }}>
                                                            <h2 class="m-2 text-dark">
                                                                Risk
                                                            </h2>
                                                        </div>

                                                        {/* <div className="order-details">
                                                        <div className="detail-row">
                                                            <div className="detail-key">Order Confirmation Date</div>
                                                            <div className="detail-value">26/11/2023</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Lead Time</div>
                                                            <div className="detail-value">210 days</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Available Time - Installation</div>
                                                            <div className="detail-value">170 days</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-key">Estimated Shipment Delay</div>
                                                            <div className="detail-value">40 days</div>
                                                        </div>
                                                    </div> */}

                                                        <tabel>
                                                            <tbody className='table table-striped' id='sideOverlaytable'>
                                                                <tr>
                                                                    <td className='header'>Production delay <small>(in Days)</small> </td>
                                                                    <td>{riskData && riskData.productionDelay}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='header'>Need by date </td>
                                                                    <td>{riskData && moment(riskData.needByDate).format('DD-MMM-YYYY')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='header'>Logistics delay <small>(in Days)</small></td>
                                                                    <td>{riskData && riskData.logisticsDelay}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='header'>ETA delay <small>(in Days)</small></td>
                                                                    <td>{riskData && riskData.etaDelay}</td>
                                                                </tr>

                                                            </tbody>
                                                        </tabel>
                                                    </div>
                                                    <div className='col-6' style={{ borderLeft: "1px solid #666" }}>
                                                        <div className='title row' style={{ justifyContent: "center" }}>
                                                            <h2 class="m-2 text-dark">
                                                                RISK MITIGATION
                                                            </h2>
                                                        </div>
                                                        <p style={{ marginTop: 10 }}>
                                                            Recommended suppliers / Site to get on-time
                                                            delivery or delay less than current ETA
                                                        </p>
                                                        <ol className='ml-0' st9yle={{ lineHeight: 2, fontSize: 13 }} >
                                                            {risks.map((item) => (
                                                                <li><strong>{item.supplierName}</strong> - OnHand ({item.inventory != 0 ? item.inventory + ' EA' : item.inventory}) - {item.recommendedSupplier.deviationDaysToNeedByDate === 0 ? 'On Time' : 'Delay'} ({moment(item.recommendedSupplier.expectedDeliveryDate).format('DD-MMM-YYYY')})</li>
                                                            ))}
                                                        </ol>
                                                        {/* <table class="table table-condensed">
                                                            <tbody>
                                                                <tr>
                                                                    <td><strong>Supplier ID</strong></td>
                                                                    <td><strong>Lead Days</strong></td>
                                                                </tr>
                                                                {risks.map((item) => (
                                                                    <tr>
                                                                        <td>{item.preferredSupplierId}</td>
                                                                        <td>{item.leadDays}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table> */}

                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
