import React from 'react'
import DataTable from "react-data-table-component";
export default function ShipmentDocuments() {
    const columns = [
        {
            name: "Document Id",
            selector: "documentId",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Document Type",
            selector: "documentType",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "File Name",
            selector: "fileName",
            sortable: true,
            minWidth: '120px'
        },
        {
            name: "Reference #",
            selector: "reference",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Upload By",
            selector: "uploadBy",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "View Doc",
            selector: 'viewDoc',
            width: '200px',
            // cell: (row) => <a
            //     style={{
            //         width: "100%",
            //         lineHeight: 0.5,
            //         color: "blue",
            //         cursor: 'pointer'
            //     }}
            // >
            //     <i class="fa fa-eye" aria-hidden="true"></i>
            // </a>
            cell: (row) => (
                <>
                    <a href={row.docPath} target="_blank" style={{
                    width: "100%",
                    lineHeight: 0.5,
                    color: "blue",
                    cursor: 'pointer'
                }}>
                        <i class="fa fa-eye" aria-hidden="true"></i>
                    </a>
                </>
            )
        },

    ];
    const data = [
        { id: 1, documentId: 'INVOICE', documentType: 'Pdf', fileName: 'INVOICE1', reference: 'INV-19738', uploadBy: 'BWL' },
        { id: 2, documentId: 'INVOICE', documentType: 'Pdf', fileName: 'INVOICE2', reference: 'INV-197334', uploadBy: 'abc electronics ltd.' },
        { id: 3, documentId: 'HBL', documentType: 'Pdf', fileName: 'HBL1', reference: 'IZMA09104', uploadBy: 'abc electronics ltd.' },
        { id: 4, documentId: 'MBL', documentType: 'Pdf', fileName: 'MBL1', reference: 'IZMA091056', uploadBy: 'abc electronics ltd.' },
        { id: 5, documentId: 'HBL', documentType: 'Pdf', fileName: 'HBL2', reference: 'IZMA091034', uploadBy: 'abc electronics ltd.' },

    ];
    return (
        <div><DataTable
            columns={columns}
            data={data}
            pagination
            dense
            selectableRowsHighlight='true'
            compact
            highlightOnHover='true'
            striped
        /></div>
    )
}
