import React, { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import { getBulkUploadResubmit, getPreviewStatus, softDemandUpload } from "../pages/const";
import Swal from 'sweetalert2';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

const UploadStatusPreview = ({ changeTab,requestData }) => {
    console.log(changeTab,"change tab")
    const columns = [
        {
            name: "File Id",
            selector: row => row.id,
            cell: (row) => <a
                style={{
                    width: "100%",
                    lineHeight: 0.5,
                    color: "blue",
                    cursor: 'pointer'
                }}
                onClick={() => uploadLineInfo(row.id)}
            >
                {row.id}
            </a>,
            sortable: true,
            minWidth: '400px'
        },
        {
            name: "File Name",
            selector: row => row.fileName,
            sortable: true,
        },
        {
            name: "File Type",
            selector: row => row.fileType,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Valid Count",
            selector: row => row.validCount,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Invalid Count",
            selector: row => row.invalidCount,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Upload Date",
            selector: row => moment(row.uploadDate).format('DD-MMM-YYYY'),
            sortable: true,
        },
        {
            name: "Action",
            selector: row => row.uploadDate,
            sortable: true,
            minWidth: '200px',
            cell: row => <>
                {row.invalidCount != 0 &&
                    <div className='row m-1'>
                        <input type="submit" value="Resubmit" class="btn btn-outline-success btn-sm" onClick={() => reSubmit(row)} />
                        {/* <button
                            type="submit"
                            className="btn btn-block btn-outline-success btn-sm"
                            onClick={reSubmit(row)}
                            class="btn btn-outline-success btn-sm"
                        >
                            Resubmit
                        </button> */}
                    </div>
                }

            </>
        },


    ];
    const [data, setData] = useState([]);
    const [errorCount, setErrorCount] = useState();
    const [totalCount, setTotalCount] = useState();
    const history = useHistory();
    const customRowStyles = (row) => {
        // console.log("row.errorExist:", row.children[2][0].props.row.errorExist); // Log errorExist value
        return {
            color: row.children[2][0].props.row.errorExist === false ? 'inherit !important' : (row.children[2][0].props.row.errorExist === true ? 'red !important' : ''),
        }
    };
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="white" />,
    })
    const [showLoader, setShowLoader] = useState(false);
    const uploadLineInfo = (data) => {
        console.log(data, "line data")
        const id = data; // Replace 123 with your actual ID value
        const path = `/uploadStatus/${id}`;
        history.push(path);

    }
    const reSubmit = (data) => {
        console.log(data, "resubmit data")
        let resubmitPayload={
            fileId:data.id
        }
        changeTab('Uploads');
        requestData(data)
    }
    
    useEffect(() => {
        getPreviewData()
    }, [])
    const getPreviewData = () => {
        getPreviewStatus().then((res) => {
            console.log(res.data, "respo")
            if (res.data.data.length !== 0) {
                setData(res.data.data);
                // const countWithErrorExist = data.filter(item => {
                //     // console.log("Processing item:", item.errorExist);
                //     return item.errorExist === true;
                // }).length;
                // const totalCount = data.length;
                // setErrorCount(countWithErrorExist);
                // setTotalCount(totalCount)
                // console.log("Count of objects with errorExist=false:", errorCount);
            }
        })
    }
    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        //selectableRows
                        // selectableRowsComponent={BootyCheckbox}
                        // onSelectedRowsChange = {handleChange}
                        dense
                        selectableRowsHighlight='true'
                        compact
                        highlightOnHover='true'
                        striped
                        customStyles={{
                            rows: {
                                style: customRowStyles
                            }
                        }}
                    />
                </div>
            </div>


        </>
    )
}

export default UploadStatusPreview;